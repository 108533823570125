import React, { useEffect } from 'react';
import { createSearchParams,useNavigate } from 'react-router-dom';

const GoogleRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      navigate({
        pathname: '/sign-google',
        search: createSearchParams({
          code: code,
        }).toString(),
      });
    } else {
      console.log({
        isShown: true,
        message: "Authorization code not found",
        messageType: "error",
      });
    }
  }, [navigate]);

  return <div>Redirecting...</div>;
};

export default GoogleRedirect;