import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";

import CustomToaster from "../CustomToaster";
import UsageDisclaimerPopup from "../usageDisclaimerPopup/UsageDisclaimerPopup";
import WelcomeAnimation from "../welcome-animation/WelcomeAnimation";

import "./Style.scss";

export const NavigateRoom = () => {
  const [{ loading }, makeRequest] = useAxios({}, { manual: true });
  const [toasterState, setToasterState] = useState({
    isShown: false,
    message: "",
    messageType: "",
  });
  const navigate = useNavigate();
  const [isUsageDisclaimer, setIsUsageDisclaimer] = useState(false);
  const [currentSession, setCurrentSession] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginToken = localStorage.getItem("token");

        if (!loginToken) {
          const response = await makeRequest({
            url: "/auth/guest",
            method: "post",
          });

          localStorage.setItem("isGuest", "true");
          localStorage.setItem("token", response?.data?.token);
        }

        const { data } = await makeRequest({
          url: "/chats/session",
          method: "post",
        });

        const chatIds: string[] = JSON.parse(
          localStorage.getItem("chatIds") || "[]"
        );

        chatIds.push(data?.session?.id);

        localStorage.setItem("chatIds", JSON.stringify(chatIds));
        const isUsageDisclaimer = JSON.parse(
          localStorage.getItem("isUsageDisclaimerChecked")!
        );
        if (isUsageDisclaimer === null && !isUsageDisclaimer) {
          setIsUsageDisclaimer(true);
          setCurrentSession(data?.session?.id);
        } else {
          navigate(`/room/${data?.session?.id}`);
        }
      } catch (error: any) {
        if (error.response?.status === 500) {
          setToasterState({
            isShown: true,
            message: "Something Went wrong",
            messageType: "error",
          });
        } else if (
          !error.response.data.error_message.includes("Rate limit reached")
        ) {
          setToasterState({
            isShown: true,
            message: error.response.data.error_message,
            messageType: "error",
          });
        }
      }
    };

    fetchData();
  }, []);

  const handleCheckboxClicked = (isChecked: boolean) => {
    if (isChecked) {
      localStorage.setItem("isUsageDisclaimerChecked", "true");
      navigate(`/room/${currentSession}`);
    }
  };

  const handleToasterClose = () => {
    setToasterState({ ...toasterState, isShown: false });
  };

  return (
    <div className="navigate-room-wrapper">
      {loading && <WelcomeAnimation />}
      {isUsageDisclaimer && (
        <div className="usgae-card-wrapper">
          <div className="usgae-card">
            <UsageDisclaimerPopup handleCheckboxClick={handleCheckboxClicked} />
          </div>
        </div>
      )}
      <CustomToaster
        isShown={toasterState.isShown}
        handleClose={handleToasterClose}
        message={toasterState.message}
        messageType={toasterState.messageType}
      />
    </div>
  );
};
