import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import useAxios from "axios-hooks";
import {v4 as uuidv4} from "uuid";

import AudioRecording from "@/components/Chat/AudioRecording";
import ThemeContext from "@/themeContext/themeContext";

import close from "../../assets/images/close.svg";
import closeDark from "../../assets/images/closeDark.svg";
import dayNightIcon from "../../assets/images/dayNightIcon.svg";
import dayNightIconDark from "../../assets/images/dayNightIconDark.svg";
import LightLogoGaiia from "../../assets/images/lightgaiialogo.svg";
import LogoGaiia from "../../assets/images/logoGaiia.svg";
import MiniLogoii from "../../assets/images/miniLogoii.svg";
import MiniLogoiiDark from "../../assets/images/miniLogoiiDark.svg";
import profileIconDark from "../../assets/images/profileIconDark.svg";
import Message from "../Chat/Message";
import CustomToaster from "../CustomToaster";
import Loader from "../loader/loader";

import "./Style.scss";

const messages = [{id: "a", text: "Hi there!", sender: "bot"}];

const scrollToBottom = (div: HTMLElement | null) => {
  setTimeout(() => {
    if (div) {
      div.scrollTop = div.scrollHeight + 1000;
    }
  }, 10);
};

const MINUTE_MS = 60000;

export type Message = (typeof messages)[0];

const Chatbox = ({
                   sessionId,
                   onClose,
                   chatData,
                 }: {
  sessionId: string;
  onClose: () => void;
  chatData: any;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState("");
  const inputRef = useRef<any>(null)
  const [{loading}, makeRequest] = useAxios({}, {manual: true});
  const userInfo = localStorage.getItem("isGuest");
  const isGuest = userInfo === "false" ? false : Boolean(userInfo);
  const [summaryData, setSummaryData] = useState<any>("Hello");
  const userString = localStorage?.getItem("user");
  const user = userString ? JSON.parse(userString) : "";
  const [{loading: endingChat}, callEndChat] = useAxios(
    {
      method: "patch",
    },
    {manual: true, autoCancel: false}
  );
  const [messages, setMessages] = useState<Message[]>([]);
  const [toasterState, setToasterState] = useState({
    isShown: false,
    message: "",
    messageType: "",
  });

  useEffect(() => {
    localStorage.setItem("lastActivity", new Date().toString());
    const interval = setInterval(() => {
      const getLastActivity = new Date(localStorage.getItem("lastActivity")!);
      const currentDate: any = new Date();
      const previousDate: any = new Date(getLastActivity);
      const timeDifference = currentDate - previousDate;
      const tenMinutesInMilliseconds = 10 * 60 * 1000;

      if (timeDifference >= tenMinutesInMilliseconds) {
        const isGuestUser = JSON.parse(localStorage.getItem("isGuest")!);
        const isGuest = isGuestUser && Boolean(isGuestUser);
        if (isGuest !== null && !isGuest) {
          endChat(false);
        } else {
          endChat(true);
        }
      }
    }, MINUTE_MS);

    return () => {
      clearInterval(interval);
      localStorage.removeItem("lastActivity");
    };
  }, []);

  useEffect(() => {
    // if (location?.state?.prevSession) {
    //   if (chatData?.session) {
    //     setMessages([
    //       { text: location?.state?.prevSession, sender: "bot", id: "123" },
    //     ]);
    //     const div = document.getElementById("chat-box");
    //     scrollToBottom(div);
    //   }
    // }
    // if (chatData?.session?.summary) {
    //   setMessages([
    //     { text: chatData?.session?.summary, sender: "bot", id: "123" },
    //   ]);
    //   const div = document.getElementById("chat-box");
    //   scrollToBottom(div);
    // }

    if (chatData?.session?.chats) {
      const modifiedChats = chatData.session.chats.map((chat: any) => {
        if (chat.message) {
          chat.message = updateUserName(chat.message);
        }
        return chat;
      });
      setMessages((prevMessages) => [...prevMessages, ...modifiedChats]);
      const div = document.getElementById("chat-box");
      scrollToBottom(div);
    }
  }, [chatData]);

  const updateUserName = (inputString: string) => {
    return inputString.replace(
      /\[User's Name\]|Name\]/g,
      user ? user?.name : "Guest"
    );
  };

  const addMessage = useCallback((m: Message) => {
    setMessages((prev) => prev.concat([m]));
  }, []);

  const sendMessage = useCallback(() => {
    localStorage.setItem("lastActivity", new Date().toString());
    addMessage({id: uuidv4(), text: input, sender: "user"});
    setInput("");
    const div = document.getElementById("chat-box");
    scrollToBottom(div);

    makeRequest({
      url: "/chats",
      method: "post",
      data: {
        session_id: sessionId,
        message: input,
      },
    })
      .then(({data}) => {
        console.log(data);
        addMessage({
          id: data.chat.id,
          text: data.chat.message,
          sender: "bot",
        });
        scrollToBottom(div);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setToasterState({
            isShown: true,
            message: "Something Went wrong",
            messageType: "error",
          });
        } else if (
          !error.response.data.error_message.includes("Rate limit reached")
        ) {
          setToasterState({
            isShown: true,
            message: error.response.data.error_message,
            messageType: "error",
          });
        }
      });
  }, [sessionId, input]);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage()
    }
  }

  const handleInputChange = useCallback((event: any) => {
    setInput(event.target.value);
  }, []);

  const formSubmit = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    document.body.classList.add("chat-mobile-wrapper");

    return () => {
      document.body.classList.remove("chat-mobile-wrapper");
    };
  }, []);

  const endChat = useCallback(
    (isEndGuestSession: boolean) => {
      callEndChat({
        url: `/chats/session/${sessionId}/end`,
      })
        .then(() => {
          navigate(isEndGuestSession ? "/sign-in" : "/dashboard");
          // onClose();
        })
        .catch((err) => {
          // onClose();
        });
    },
    [sessionId]
  );

  const loginchat = useCallback(() => {
    // TODO: Modified functionion, because of no callback was processed before
    // deleted all not needed code and moved out navigate function
    // login here should be rewritten and adding some error catching
    callEndChat({
      url: `/chats/session/${sessionId}/end`,
    })
    navigate("/sign-in");
  }, [sessionId]);

  const {theme, setAutoThemeMode} = useContext(ThemeContext);

  const gotoDashboard = () => {
    if (!isGuest) {
      navigate("/dashboard");
    } else {
      navigate("/home");
    }
  };

  const handleToasterClose = () => {
    setToasterState({...toasterState, isShown: false});
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawerWidth = "100%";
  const navItems = [
    {name: "about us", route: "/about-us"},
    {name: "how it works", route: "/how-it-works"},
    {name: "contact us", route: "/contact-us"},
    // { name: "about gaiia", route: "/home" },
  ];

  const [isAudioOpen, setIsAudioOpen] = useState(false)
  const handleSubmitAudio = (text: string) => {
    setTimeout(() => {
      setInput(text)
      setIsAudioOpen(false)
      inputRef.current?.focus();
    }, 10)
  }

  return (
    <Box
      sx={{
        height: "100svh",
        width: "80vw",
        display: "grid",
        bgcolor: "grey.200",
      }}
      className="chatbox-wrap"
    >
      <CustomToaster
        isShown={toasterState.isShown}
        handleClose={handleToasterClose}
        message={toasterState.message}
        messageType={toasterState.messageType}
      />

      {endingChat && <Loader/>}

      <Box
        sx={{
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          padding: "20px",
          width: "80vw",
          justifyContent: "space-between",
        }}
        className="header-box"
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
            gap: "1rem",
          }}
        >
          <Box sx={{cursor: "pointer"}} onClick={gotoDashboard}>
            {!isGuest ? (
              <>
                <img
                  src={theme === "light" ? MiniLogoii : MiniLogoiiDark}
                  className="logo-icon"
                  alt="logo"
                />
              </>
            ) : (
              <>
                <img
                  src={theme === "light" ? LogoGaiia : LightLogoGaiia}
                  alt=""
                  className="desktop-logo-icon"
                />
              </>
            )}
          </Box>

          {!isGuest ? (
            <>
              <Box
                sx={{cursor: "pointer"}}
                onClick={() => {
                  setAutoThemeMode(false, theme === "light" ? "dark" : "light");
                }}
              >
                <img
                  src={theme === "light" ? dayNightIcon : dayNightIconDark}
                  alt="day-night"
                  className="day-night"
                />
              </Box>
            </>
          ) : (
            ""
          )}
        </Box>

        <Box
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
            gap: "0.5rem",
          }}
        >
          {!isGuest ? (
            <>
              <Box sx={{cursor: "pointer"}} onClick={gotoDashboard}>
                <img
                  src={theme === "light" ? profileIconDark : profileIconDark}
                  alt="day-night"
                  style={{marginTop: "12px"}}
                />{" "}
              </Box>
              <span className="userName">
                {!isGuest
                  ? user && user.name
                    ? user.name
                    : user && user.email
                      ? user.email.split("@")[0]
                      : ""
                  : ""}
              </span>
              <Box
                onClick={() => endChat(false)}
                sx={{p: 2, cursor: "pointer"}}
                className="end-session-btn-box"
              >
                <Button
                  color="inherit"
                  className="end-session-btn"
                  onClick={() => endChat(false)}
                >
                  end session
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Button
                color="inherit"
                className="log-in-button"
                onClick={loginchat}
              >
                log in
              </Button>
            </>
          )}
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{marginTop: "0px", paddingTop: "8px"}}
            >
              <MenuIcon sx={{fontSize: "2rem"}} className="drawer-icon"/>
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box
        id="chat-box"
        sx={{
          overflow: "auto",
          p: 2,
          height: "65vh",
        }}
        className="mobile-chat-box"
      >
        {messages.map((message) => {
          return (
            <Message key={message.id} message={message}/>
          );
        })}

        {loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            <span className="typing-text">thinking...</span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Paper
                variant="outlined"
                className="bot-chat-bg"
                sx={{
                  p: 2,
                  ml: 1,
                  mr: 0,
                  borderRadius: "20px 20px 20px 20px",
                }}
              >
                <Typography variant="body1">...</Typography>
              </Paper>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{pt: 2, pb: 2, backgroundColor: "background.default"}}>
        <form
          onSubmit={formSubmit}
          name="chat-input"
          target="#here"
          className="chat-input"
        >
          <Box sx={{display: "flex", alignItems: 'center', gap: "8px"}}>
            {!isAudioOpen && <Grid container spacing={0} className="input-container">
                <Grid item xs={9} className="input-grid">
                    <TextField
                        size="small"
                        fullWidth
                        placeholder="Type a message"
                        variant="outlined"
                        disabled={endingChat}
                        inputRef={inputRef}
                        value={input}
                        multiline
                        maxRows={3}
                        onChange={handleInputChange}
                        onKeyDown={handleEnterPress}
                    />
                </Grid>
                <Grid item xs={2} className="send-btn">
                    <Button
                        fullWidth
                        disabled={input === ""}
                        color="primary"
                        type="submit"
                        variant="contained"
                        onClick={sendMessage}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>}
            <AudioRecording isOpen={isAudioOpen} setIsAudioOpen={setIsAudioOpen} onSubmit={handleSubmitAudio}/>
          </Box>
        </form>
      </Box>

      <Box
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          padding: "20px",
          paddingTop: "0px",
        }}
        className="mobile-header-box"
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
            gap: "20px",
          }}
        >
          <Box sx={{cursor: "pointer"}} onClick={gotoDashboard}>
            {localStorage.getItem("token") ? (
              <img
                src={theme === "light" ? MiniLogoii : MiniLogoiiDark}
                className="logo-icon"
                alt="logo"
              />
            ) : (
              <>
                <img
                  src={theme === "light" ? LogoGaiia : LightLogoGaiia}
                  alt=""
                  className="mobile-show-img"
                />
              </>
            )}
          </Box>
          {!isGuest ? (
            <div>
              <Box sx={{cursor: "pointer"}} onClick={gotoDashboard}>
                <img
                  src={theme === "light" ? profileIconDark : profileIconDark}
                  alt="day-night"
                  className="logo-icon"
                />
                <span className="userName">{user?.name ? user?.name : ""}</span>
              </Box>
              <Box
                onClick={() => endChat(false)}
                sx={{p: 2, cursor: "pointer"}}
                className="mobile-end-session-btn-box"
              >
                <Button
                  color="inherit"
                  className="mobile-end-session-btn"
                  onClick={() => endChat(false)}
                >
                  end session
                </Button>
              </Box>
            </div>
          ) : (
            <Button
              color="inherit"
              className="log-in-button"
              onClick={loginchat}
            >
              log in
            </Button>
          )}
        </Box>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon
              sx={{fontSize: "2rem"}}
              className="mobile-drawer-icon"
            />
          </IconButton>
        </Box>
      </Box>

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        className={theme === "light" ? "theme--light" : "theme--dark"}
        sx={{
          display: {xs: "block", md: "block", lg: "block"},
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{textAlign: "center"}}>
          <List>
            {navItems.map((item) => (
              <ListItem key={item?.name} disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (item?.name === "Logout") {
                      localStorage.clear();
                      sessionStorage.clear();
                    }
                    navigate(item.route);
                  }}
                >
                  {item?.name}
                </ListItemButton>
              </ListItem>
            ))}
            {isGuest && (
              <div className="menu-button">
                <Button
                  color="inherit"
                  onClick={() => navigate("/sign-in")}
                  className="light-theme-btn"
                >
                  Log in
                </Button>
                <Button
                  color="inherit"
                  onClick={() => navigate("/sign-up")}
                  className="light-theme-btn"
                >
                  sign up
                </Button>
              </div>
            )}
          </List>
        </Box>
        <img
          src={theme === "light" ? close : closeDark}
          alt=""
          className="drawer-close-icon"
          onClick={handleDrawerToggle}
        />
      </Drawer>
    </Box>
  );
};


export default Chatbox;
