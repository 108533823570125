import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

import "./Style.scss";

interface response {
  response: string | "No Response";
}

const SingInWithGoogle = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const chatArray = JSON.parse(localStorage.getItem("chatIds")!) || [];
    axios
      .post<response>(`auth/google/login`, {
        code: searchParams.get("code"),
        guest_session_ids: chatArray,
      })
      .then((response: any) => {
        if (response.data) {
          console.log("response", response.data); // for debugging
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("isGuest", "false");
          localStorage.setItem("user", JSON.stringify(response.data.user));
          const chatIds = JSON.parse(localStorage.getItem("chatIds")!) || [];
          if (chatIds && chatIds.length > 0) {
            const lastSessionid = chatIds[chatIds?.length - 1];
            navigate(`/room/${lastSessionid}`);
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //  eslint-disable-next-line
  }, []);

  return (
    <div className="sign-in-google">
      <h1> Loading... </h1>
    </div>
  );
};

export default SingInWithGoogle;
