import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useAxios from "axios-hooks";

import CustomToaster from "@/components/CustomToaster";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/Header";
import Loader from "@/components/loader/loader";
import ThemeContext from "@/themeContext/themeContext";

import LightLogoGaiia from "../../assets/images/lightgaiialogo.svg";
import LogoGaiia from "../../assets/images/logoGaiia.svg";

import "./Style.scss";

const AboutUs = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [toasterState, setToasterState] = useState({
    isShown: false,
    message: "",
    messageType: "",
  });
  const [{ loading }, makeRequest] = useAxios({}, { manual: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startSession = useCallback(async () => {
    const loginToken = localStorage.getItem("token");

    if (!loginToken) {
      await makeRequest({
        url: "/auth/guest",
        method: "post",
      })
        .then((response) => {
          localStorage.setItem("isGuest", "true");
          localStorage.setItem("token", response?.data?.token);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            setToasterState({
              isShown: true,
              message: "Something Went wrong",
              messageType: "error",
            });
          } else if (
            !error.response.data.error_message.includes("Rate limit reached")
          ) {
            setToasterState({
              isShown: true,
              message: error.response.data.error_message,
              messageType: "error",
            });
          }
        });
    }

    await makeRequest({
      url: "/chats/session",
      method: "post",
    })
      .then(({ data }) => {
        const chatIds = JSON.parse(localStorage.getItem("chatIds")!) || [];
        chatIds.push(data?.session?.id);
        localStorage.setItem("chatIds", JSON.stringify(chatIds));
        navigate(`/room/${data?.session?.id}`, {
          state: { prevSession: data?.session?.prev_session },
        });
      })

      .catch((error) => {
        if (error.response.status === 500) {
          setToasterState({
            isShown: true,
            message: "Something Went wrong",
            messageType: "error",
          });
        } else if (
          !error.response.data.error_message.includes("Rate limit reached")
        ) {
          setToasterState({
            isShown: true,
            message: error.response.data.error_message,
            messageType: "error",
          });
        }
      });
  }, []);

  const handleToasterClose = () => {
    setToasterState({ ...toasterState, isShown: false });
  };

  return (
    <>
      {loading && <Loader />}
      <Header />
      <CustomToaster
        isShown={toasterState.isShown}
        handleClose={handleToasterClose}
        message={toasterState.message}
        messageType={toasterState.messageType}
      />

      <div className="about-us">
        <div
          className={
            theme === "light" ? "center-circle-light" : "center-circle-dark"
          }
        ></div>
        <div className="circle-bg">
          <div className="head-section">
            <h1>
              welcome to{" "}
              <img
                src={theme === "light" ? LogoGaiia : LightLogoGaiia}
                alt=""
              />
            </h1>
            <p className="light-p">
            My name is Margarita. My ambition is to change the world by making joy more accessible and present in our fast-paced lives.
            </p>
            <p className="light-p">
            Named in homage to Siena’s La Fonte Gaia – the Fountain of Joy, Gaiia's unique spelling with twin 'ii's symbolises the two people turning to each other, underscoring the importance of human connections for happiness.  Much like that historical marvel that channelled life-giving waters to rejuvenate an entire city, our app serves as a conduit of rich mental well-being resources. It's a testament to how Gaiia, akin to the fountain, is a source of nourishment, revitalising the spirit of its users with knowledge, clarity and calm.
            </p>
            <p className="light-p">
            With Gaiia, life becomes a little simpler and less stressful. By integrating vast scientific knowledge, training, and human experiences, Gaiia offers insights beyond the capacity of any one therapist or coach.  It’s attuned to your emotional state and tailored to your unique journey.
            </p>
            <p className="light-p">
            Gaiia is designed to be both an emphatic guru and a fountain of knowledge. This project extends beyond mere technology; it's a step towards a world rich in joy and connectedness. My mission is to uplift personal well-being and make the world a better place, one smile at a time.
            </p>
            <p className="light-p">
            I invite you to join us. With Gaiia, let's uncover joy, spread happiness, and together, change the world.
            </p>
          </div>
        </div>

        <div className="card-section">
          <div className="card-maincontainer">
            <div className="card-title-dark">
              <div className="card-dark-bg"></div>
              <h1>accessible anytime, anywhere:</h1>
            </div>
            <div className="card-detail">
              <p>
                Life doesn't adhere to schedules, and neither do your thoughts
                and emotions.{" "}
                <b>
                  Gaiia is available around the clock, whenever you need a
                  listening ear or a moment of introspection.
                </b>{" "}
                Our platform is designed to fit seamlessly into your life,
                allowing you to engage in therapeutic conversations whenever
                it's most convenient for you.
              </p>
            </div>
            <div className="card-title-light">
              <div className="card-light-bg"></div>
              <h1>confidentiality at its core:</h1>
            </div>
            <div className="card-detail">
              <p>
                <b>Your privacy is our utmost priority.</b> Gaiia is built to be
                a haven for open expression, allowing you to share thoughts you
                might not feel comfortable discussing with another human. Rest
                assured that your conversations are held in strict confidence,
                and we do not share your personal information with any third
                parties. Your trust in our platform's confidentiality is the
                foundation of our service.
              </p>
            </div>
            <div className="card-title-dark">
              <div className="card-dark-bg"></div>
              <h1>unparalleled insights through AI:</h1>
            </div>
            <div className="card-detail">
              <p>
                <b>
                  Our use of artificial intelligence enables Gaiia to offer you
                  guidance that goes beyond what traditional therapy might
                  provide.
                </b>{" "}
                AI has the ability to access a vast repository of studies and
                data, allowing our platform to offer tailored advice and
                guidance in line with your unique personality and challenges.
                With Gaiia, you're tapping into a wealth of knowledge that can
                help you navigate your emotional landscape with greater clarity.
              </p>
            </div>
            <div className="card-title-light">
              <div className="card-light-bg"></div>
              <h1>a judgment-free zone:</h1>
            </div>
            <div className="card-detail">
              <p>
                We understand the importance of feeling understood and accepted.
                <b>
                  {" "}
                  With Gaiia, you'll never have to worry about being judged or
                  criticized.
                </b>{" "}
                Our AI platform's compassionate responses are designed to create
                an environment where you can explore your thoughts and feelings
                without the weight of external opinions.
                <b>
                  Your emotions are valid, and Gaiia is here to support you
                  without reservation.
                </b>
              </p>
              <p>
                <b>
                  Gaiia is more than just an AI therapy chatbot – it's a
                  companion on your journey toward emotional well-being
                </b>{" "}
                . We're committed to continuously improving our platform to
                better serve your needs and provide a safe, nurturing space for
                you to explore your thoughts and emotions.
              </p>
              <p>
                <b>
                  {" "}
                  Welcome to Gaiia, where therapy is accessible, confidential,
                  and free from judgment. Start your conversation today and
                  experience the power of AI-driven support like never before.
                </b>
              </p>
            </div>
          </div>
        </div>
        {/* <div
          className={
            theme === "light"
              ? "join-section join-section-light"
              : "join-section"
          }
        >
          <Button>let’s chat</Button>
        </div> */}
        <div className="join-content">
          <div className="join-bg-section"></div>
          <div className="join-section">
            <Button onClick={startSession} className="light-theme-btn">
              let’s chat
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
